<!-- 奖金计算 -->
<template>
    <div class="">
      <ycheader></ycheader>
      <div class="clear"></div>
    </div>
    <router-view />
    <ycfooter></ycfooter>
  </template>
  
  <script>
  import ycheader from "@/components/yuce/yc-header.vue";
  import ycfooter from "@/components/Footer.vue";
  export default {
    name: "Number-select",
    components: {
      ycheader,
      ycfooter,
     
    },
    data() {
      // 数据源
      return {};
    },
    mounted() {},
    computed: {
      // sd() {
      //     return this.$store.state.sd;
      // },
    },
    methods: {
      // 函数体部分，js的主要逻辑控制
    },
    props: {},
  };
  </script>
  
  <style scoped>
  /* @import './swiper.css'; */
  .clear {
    clear: both;
  }
  </style>